import React from 'react';
import { Text, Box, Stack } from '@chakra-ui/react'
import { homeText1, homeText1b, homeText2, homeText2b, homeText3, homeText3b, homeText4, homeText4b } from '../homeDynamicTexts';


const DescriptionCards = () => {
	return (
		<>
			{/* // <VStack spacing={[5, 5, 5, 6]} align={'left'} > */}
			<Stack spacing={[0]}>
				<Box
					p={[0, 1]}
					// borderWidth={1}
					// borderRadius="md"
					// borderColor={'green'}
					w="80%"
					// maxW="600px"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="white"
						borderWidth={2}
						borderRadius="full"
						borderColor={'green'}
						marginRight={3}
						my={'auto'}
					// display="flex"
					// alignItems="center"
					// justifyContent="center"
					// color="white"
					/>

					<Text>{homeText1}</Text>
				</Box>

				<Box
					p={[0, 1]}
					// borderWidth={1}
					// borderRadius="md"
					// borderColor={'green'}
					w="80%"
					// maxW="600px"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="white"
						borderWidth={2}
						borderRadius="full"
						borderColor={'green'}
						marginRight={3}
						my={'auto'}
					// display="flex"
					// alignItems="center"
					// justifyContent="center"
					// color="white"
					/>

					<Text>{homeText1b}</Text>
				</Box>
			</Stack>

			<Stack spacing={[0]}>
				<Box
					p={[0, 1]}
					// borderWidth={1}
					// borderRadius="md"
					// borderColor={'green'}
					w="80%"
					// maxW="600px"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="white"
						borderWidth={2}
						borderRadius="full"
						borderColor={'green'}
						marginRight={3}
						my={'auto'}
					// display="flex"
					// alignItems="center"
					// justifyContent="center"
					// color="white"
					/>

					<Text>{homeText2}</Text>
				</Box>

				<Box
					p={[0, 1]}
					// borderWidth={1}
					// borderRadius="md"
					// borderColor={'green'}
					w="80%"
					// maxW="600px"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="white"
						borderWidth={2}
						borderRadius="full"
						borderColor={'green'}
						marginRight={3}
						my={'auto'}
					// display="flex"
					// alignItems="center"
					// justifyContent="center"
					// color="white"
					/>

					<Text>{homeText2b}</Text>
				</Box>
			</Stack>

			<Stack spacing={[0]}>
				<Box
					p={[0, 1]}
					// borderWidth={1}
					// borderRadius="md"
					// borderColor={'green'}
					w="80%"
					// maxW="600px"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="white"
						borderWidth={2}
						borderRadius="full"
						borderColor={'green'}
						marginRight={3}
						my={'auto'}
					// display="flex"
					// alignItems="center"
					// justifyContent="center"
					// color="white"
					/>

					<Text>{homeText3}</Text>
				</Box>

				<Box
					p={[0, 1]}
					// borderWidth={1}
					// borderRadius="md"
					// borderColor={'green'}
					w="80%"
					// maxW="600px"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="white"
						borderWidth={2}
						borderRadius="full"
						borderColor={'green'}
						marginRight={3}
						my={'auto'}
					// display="flex"
					// alignItems="center"
					// justifyContent="center"
					// color="white"
					/>

					<Text>{homeText3b}</Text>
				</Box>
			</Stack>

			<Stack spacing={[0]}>
				<Box
					p={[0, 1]}
					// borderWidth={1}
					// borderRadius="md"
					// borderColor={'green'}
					w="80%"
					// maxW="600px"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="white"
						borderWidth={2}
						borderRadius="full"
						borderColor={'green'}
						marginRight={3}
						my={'auto'}
					// display="flex"
					// alignItems="center"
					// justifyContent="center"
					// color="white"
					/>

					<Text>{homeText4}</Text>
				</Box>

				<Box
					p={[0, 1]}
					// borderWidth={1}
					// borderRadius="md"
					// borderColor={'green'}
					w="80%"
					// maxW="600px"
					display="flex"
					alignItems="left"
				>
					<Box
						width={["9px", "11px", "13px", "15px"]}
						height={["9px", "11px", "13px", "15px"]}
						bg="white"
						borderWidth={2}
						borderRadius="full"
						borderColor={'green'}
						marginRight={3}
						my={'auto'}
					// display="flex"
					// alignItems="center"
					// justifyContent="center"
					// color="white"
					/>

					<Text>{homeText4b}</Text>
				</Box>
			</Stack>
			{/* // </VStack> */}
		</>
	);
};

export default DescriptionCards;