
export const REQUEST_SUBMIT_DATA = "REQUEST_SUBMIT_DATA";
export const SUCCESS_SUBMIT_DATA = "SUCCESS_SUBMIT_DATA"
export const FAILURE_SUBMIT_DATA = "FAILURE_SUBMIT_DATA";

// export const REQUEST_SUBMIT_FILE_UPLOAD = "REQUEST_SUBMIT_FILE_UPLOAD";
// export const SUCCESS_SUBMIT_FILE_UPLOAD = "SUCCESS_SUBMIT_FILE_UPLOAD"
// export const FAILURE_SUBMIT_FILE_UPLOAD = "FAILURE_SUBMIT_FILE_UPLOAD";

// export const UPDATE_SESSION_EXPIRY = "UPDATE_SESSION_EXPIRY";
