import axios from "axios";
import {
    requestSubmitData,
    submitDataSuccess,
    submitDataFailure,
    // requestSubmitFileUpload,
    // submitFileUploadSuccess,
    // submitFileUploadFailure,
    // updateSessionExpiry,
} from "../../ActionCreator/home";
import { data_url } from "../../../allApiPath";


export const dataSubmit = (formData, token) => async (dispatch, getState) => {
    dispatch(requestSubmitData())
    console.log(formData);

    axios.post(data_url, formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'Content-Type': 'application/json',
                'Authorization': token,
            },
            // body: JSON.stringify(formData),
        }
    )
        .then((response) => {
            dispatch(submitDataSuccess(response.data));
        }, error => {
            dispatch(submitDataFailure(error))
        })
}

// export const fileUpload = (formData, token) => async (dispatch, getState) => {
//     dispatch(requestSubmitFileUpload())

//     axios.post(data_url, {
//         formData: formData,
//     },
//         {
//             headers: {
//                 'Authorization': token,
//             },
//         }
//     )
//         .then((response) => {
//             dispatch(submitFileUploadSuccess(response.data));
//         }, error => {
//             dispatch(submitFileUploadFailure(error))
//         })
// }
