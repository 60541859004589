import React from 'react';
import { Box } from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = ({ onChange }) => {
	const recaptchaRef = React.createRef();

	const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
	// console.log(siteKey);
	return (
		<Box my={4}>
			<ReCAPTCHA
				ref={recaptchaRef}
				// size="invisible"
				// sitekey={siteKey} // Replace with your reCAPTCHA site key
				sitekey="6Lc5ZCoqAAAAAMhf6-kq3AHflW14vx2aQEzn1Enz" // Replace with your reCAPTCHA site key
				onChange={onChange}
			/>
		</Box>
	);
};

export default Captcha;
