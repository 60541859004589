import React, { useEffect, useState } from 'react';
import { Container, Stack, Text, Box, Button, Input, Checkbox, Select } from '@chakra-ui/react';
import { Form } from 'react-router-dom';

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
// import Captcha from '../Components/Captcha';

import { categoriesInputHeaderLinkToPdfPage, instructionTextLinkToPdfPage, sourceTypeInputHeaderLinkToPdfPage, sourceTypeInputPlaceholderLinkToPdfPage, submitButtonTextLinkToPdfPage, urlInputHeaderLinkToPdfPage, urlInputIdLinkToPdfPage, urlInputPlaceholderLinkToPdfPage, welcomeTextLinkToPdfPage } from '../homeDynamicTexts';

import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import { dataSubmit } from '../Redux/Thunk/Home';
import { selectToken, selectData } from '../Redux/Reducer';


const LinkToPdf = () => {
	const dispatch = useDispatch();

	const token = useSelector(state => selectToken(state));
	// const profile = useSelector(state => selectProfile(state));
	const data = useSelector(state => selectData(state));

	const { innerWidth, innerHeight } = window;

	const [selectedCategoriesOptions, setSelectedCategoriesOptions] = useState([]);
	const [categoriesOptions, setCategoriesOptions] = useState([
		{ value: 'হিন্দু নির্যাতন', label: 'হিন্দু নির্যাতন' },
		{ value: 'বিরোধী রাজনৈতিক কর্মী নির্যাতন(আওয়ামীলীগ, ছাত্রলীগ, বামপন্থী)', label: 'বিরোধী রাজনৈতিক কর্মী নির্যাতন(আওয়ামীলীগ, ছাত্রলীগ, বামপন্থী)' },
		{ value: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)', label: 'ঘরবাড়ি ও কারখানা লুটপাট(আইন শৃঙ্খলা পরিস্থিতি অবনতি)' },
		{ value: 'সাংবাদিক নির্যাতন', label: 'সাংবাদিক নির্যাতন' },
		{ value: 'শিক্ষক নির্যাতন', label: 'শিক্ষক নির্যাতন' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনী কর্তৃক অত্যাচার' },
		{ value: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা', label: 'আইন শৃঙ্খলা রক্ষাকারী বাহিনীর উপরে ঘটে যাওয়া নৃশংসতা' },
		{ value: 'উসকানীমূলক বক্তব্য', label: 'উসকানীমূলক বক্তব্য' },
		{ value: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা', label: 'প্রকাশ্য জঙ্গীবাদী তৎপরতা' },
		{ value: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা', label: 'প্রকাশ্য সাম্প্রদায়িক তৎপরতা' },
		{ value: 'অগনতান্ত্রিক আচরন', label: 'অগনতান্ত্রিক আচরন' },
		{ value: 'বিচার বিভাগীয় ব্যর্থতা', label: 'বিচার বিভাগীয় ব্যর্থতা' },
		{ value: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা', label: '১৯৭১ সাল  সম্পর্কিত যেকোন ‌অপপ্রচার অথবা হামলা' },
		{ value: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা', label: 'সাংস্কৃতিক আইকন, বা মূর্তি সম্পর্কিত যেকোন হামলা' },
		{ value: 'Other', label: 'Other' },
	]);
	// const [isOpen, setIsOpen] = useState(false);
	// const toggleDropdown = () => setIsOpen(!isOpen);
	const handleCheckboxChange = (value) => {
		setSelectedCategoriesOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value]
		);
	};

	const [selectedSourceTypeOption, setSelectedSourceTypeOption] = useState('');
	const [options, setOptions] = useState([
		{ value: 'Newspaper', label: 'Newspaper' },
	]);
	const handleSourceTypeChange = (event) => {
		setSelectedSourceTypeOption(event.target.value);
		// console.log('Selected Option:', event.target.value);
	};

	const [url, setURL] = useState('');
	const handleURLChange = (e) => setURL(e.target.value);


	const handleSubmit = event => {
		event.preventDefault();

		if (selectedCategoriesOptions.length > 0) {
			const formData = new FormData();

			formData.append('categories', selectedCategoriesOptions);
			formData.append('source_type', selectedSourceTypeOption);
			formData.append('source_url', url);
			formData.append('form_type', "link-to-pdf");
			dispatch(dataSubmit(formData, token));

			setSelectedCategoriesOptions([]);
			setSelectedSourceTypeOption('');
			setURL('');
		}
		else {
			toast.error('Please select of at least one category.');
		}
	};

	useEffect(() => {
		if (data.success) {
			toast.success(data.success);
		}
		if (data.error.message) {
			toast.error(data.error.message);
		}
	}, [data.error.message, data.success, dispatch]);


	return (
		<>
			<Navbar />

			<Container
				pb={{ base: '2', md: '4', }}
				px={{ base: '3', md: '8', }}
				maxWidth={"1400px"}
				mx={'auto'}
				minH={innerHeight * 0.756}
			>
				<Box
					// rowSpan={3}
					// colSpan={[5, 5, 3, 3, 3]}
					boxShadow='dark-lg'
					p={[3, 6]}
					rounded='md'
					w={'100%'}
				>
					<Text
						fontSize={["lg", "lg", "2xl"]}
						fontWeight="bold"
						color='black'
						textAlign={'center'}
						mb={2}
					>
						{welcomeTextLinkToPdfPage}
					</Text>

					<Text
						fontSize={["lg", "lg", "2xl"]}
						fontWeight="bold"
						color='black'
						textAlign={'center'}
						mb={2}
					>
						{instructionTextLinkToPdfPage}
					</Text>

					<Box>
						{/* <Form onSubmit={handleSubmit}> */}
						<Form>
							{/* <Input */}
							{/* Categories */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'column', 'row']}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{categoriesInputHeaderLinkToPdfPage}
								</Text>

								<Box ml={5} w={['95%', '95%', '70%', '45%']}>
									{/* <Button onClick={toggleDropdown}>
										{selectedCategoriesOptions.length > 0
											? `Total Selected (${selectedCategoriesOptions.length})`
											: `${categoriesInputPlaceholder}`}
									</Button> */}

									{/* {isOpen && ( */}
									<Box border="1px solid" borderColor="gray.200" borderRadius="md" mt={2} p={2} bg="white">
										{categoriesOptions.map((option) => (
											<Box key={option.value}>
												<Checkbox
													isChecked={selectedCategoriesOptions.includes(option.value)}
													onChange={() => handleCheckboxChange(option.value)}
												>
													{option.label}
												</Checkbox>
											</Box>
										))}
									</Box>
									{/* )} */}
								</Box>
							</Stack>

							{/* Source Type */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{sourceTypeInputHeaderLinkToPdfPage}
								</Text>

								{/* <Box width="700px" margin="0 auto"> */}
								<Select
									placeholder={sourceTypeInputPlaceholderLinkToPdfPage}
									size={["md", "md", "lg"]}
									variant="filled"
									focusBorderColor="blue.500"
									borderRadius="md"
									boxShadow="sm"
									// required
									onChange={handleSourceTypeChange}
									value={selectedSourceTypeOption}
								>
									{options.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</Select>

								{/* </Box> */}
							</Stack>

							{/* URL */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{urlInputHeaderLinkToPdfPage}
								</Text>

								<Input
									name={urlInputIdLinkToPdfPage}
									id={urlInputIdLinkToPdfPage}
									variant='filled'
									type="url"
									size={["md", "md", "lg"]}
									width='100%'
									p={3}
									// my={3}
									placeholder={urlInputPlaceholderLinkToPdfPage}
									onChange={handleURLChange}
									required
									value={url}
								// h={'50px'}
								/>
							</Stack>

							<Text textAlign={'center'} my={2}>
								<Button
									size="lg"
									bg={"blue"}
									color={"white"}
									_hover={{ bg: "blue.500" }}
									px={4}
									py={1}
									borderRadius={'lg'}
									// isDisabled={(selectedCategoriesOptions && url) ? false : true}
									type='submit'
									onClick={(event) => {
										if (selectedCategoriesOptions && url) {
											handleSubmit(event);
										}
									}}
								>
									{submitButtonTextLinkToPdfPage}
								</Button>
							</Text>
						</Form>

					</Box>
				</Box >

			</Container >

			<Footer />
		</>
	);
};

export default LinkToPdf;
