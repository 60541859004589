import React, { useEffect, useState } from 'react';
import { Container, Stack, Text, Box, Button, Textarea, Input, Checkbox, Select, RadioGroup, Radio, VStack, Grid, GridItem, Center } from '@chakra-ui/react';
import { Form } from 'react-router-dom';

import Navbar from '../Components/Navbar';
import DescriptionCards from '../Components/DescriptionCards';
import Footer from '../Components/Footer';
// import Captcha from '../Components/Captcha';

import { categoriesInputHeader, victimInputHeader, victimInputPlaceholder, dateInputHeader, dateInputId, dateInputPlaceholder, descriptionInputHeader, descriptionInputId, descriptionInputPlaceholder, emailInputHeader, emailInputId, emailInputPlaceholder, fileInputHeader, instructionText, nameInputHeader, nameInputId, nameInputPlaceholder, addressInputHeader, addressInputId, addressInputPlaceholder, occupationInputHeader, occupationInputId, occupationInputPlaceholder, sourceTypeInputHeader, sourceTypeInputPlaceholder, submitButtonText, submitWaitingText, urlInputHeader, urlInputId, urlInputPlaceholder, victimInputId, welcomeText, shortDescriptionInputHeader, shortDescriptionInputId, shortDescriptionInputPlaceholder, attackPlaceCategoriesInputHeader, victimConditionInputHeader } from '../homeDynamicTexts';

import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import { dataSubmit } from '../Redux/Thunk/Home';
import { selectToken, selectData } from '../Redux/Reducer';


const Home = () => {
	const dispatch = useDispatch();

	const token = useSelector(state => selectToken(state));
	// const profile = useSelector(state => selectProfile(state));
	const data = useSelector(state => selectData(state));


	const [date, setDate] = useState('');
	const handleDateChange = (e) => setDate(e.target.value);

	const [selectedAttackPlaceCategoriesOptions, setSelectedAttackPlaceCategoriesOptions] = useState([]);
	const [attackPlaceCategoriesOptions, setAttackPlaceCategoriesOptions] = useState([
		{ value: 'গৃহ', label: 'গৃহ' },
		{ value: 'কর্মক্ষেত্র', label: 'কর্মক্ষেত্র' },
		{ value: 'অগ্নিকান্ড', label: 'অগ্নিকান্ড' },
		{ value: 'উপাসনার স্থান', label: 'উপাসনার স্থান' },
		{ value: 'স্থাপনা', label: 'স্থাপনা' },
		{ value: 'সুনির্দিষ্ট নয়', label: 'সুনির্দিষ্ট নয়' },
		{ value: 'প্রযোজ্য নয়', label: 'প্রযোজ্য নয়' },
		{ value: 'Other', label: 'Other' },
	]);
	// const [isOpen, setIsOpen] = useState(false);
	// const toggleDropdown = () => setIsOpen(!isOpen);
	const handleAttackPlaceCheckboxChange = (value) => {
		setSelectedAttackPlaceCategoriesOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value]
		);
	};

	const [selectedCategoriesOptions, setSelectedCategoriesOptions] = useState([]);
	const [categoriesOptions, setCategoriesOptions] = useState([
		{ value: 'শারীরিক আক্রমণ', label: 'শারীরিক আক্রমণ' },
		{ value: 'সম্পত্তির উপর আক্রমণ', label: 'সম্পত্তির উপর আক্রমণ' },
		{ value: 'অগ্নিকান্ড', label: 'অগ্নিকান্ড' },
		{ value: 'লুটপাট', label: 'লুটপাট' },
		{ value: 'হুমকি ও ভয় ভীতি', label: 'হুমকি ও ভয় ভীতি' },
		{ value: 'অনলাইন প্রচারণা, উসকানি, অনলাইন আক্রমণ', label: 'অনলাইন প্রচারণা, উসকানি, অনলাইন আক্রমণ' },
		{ value: 'নিগ্রহ', label: 'নিগ্রহ' },
		{ value: 'প্রযোজ্য নয়', label: 'প্রযোজ্য নয়' },
		{ value: 'Other', label: 'Other' },
	]);
	// const [isOpen, setIsOpen] = useState(false);
	// const toggleDropdown = () => setIsOpen(!isOpen);
	const handleCheckboxChange = (value) => {
		setSelectedCategoriesOptions((prev) =>
			prev.includes(value)
				? prev.filter((item) => item !== value)
				: [...prev, value]
		);
	};

	const [selectedSourceTypeOption, setSelectedSourceTypeOption] = useState('');
	const [options, setOptions] = useState([
		{ value: 'facebook', label: 'Facebook' },
		{ value: 'youtube', label: 'Youtube' },
		{ value: 'twitter', label: 'Twitter' },
		{ value: 'news', label: 'News' },
	]);
	const handleSourceTypeChange = (event) => {
		setSelectedSourceTypeOption(event.target.value);
		// console.log('Selected Option:', event.target.value);
	};

	const [url, setURL] = useState('');
	const handleURLChange = (e) => setURL(e.target.value);

	const [shortDescription, setShortDescription] = useState('');
	const handleShortDescriptionChange = (e) => setShortDescription(e.target.value);

	const [description, setDescription] = useState('');
	const handleDescriptionChange = (e) => setDescription(e.target.value);

	const [uploadFlag, setUploadFlag] = useState(false);

	// file upload 
	// for single file system
	const [selectedFile, setSelectedFile] = useState(null);
	const [preview, setPreview] = useState('');
	const [error, setError] = useState('');
	useEffect(() => {
		// Cleanup the object URLs when component unmounts or previews change
		return () => {
			if (preview) {
				URL.revokeObjectURL(preview);
			}
		};
	}, [preview]);
	const handleFileChange = (event) => {
		const file = event.target.files[0];

		const maxSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes
		// const maxSize = 1 * 1024 * 1024 * 1024; // 1GB in bytes

		if (file) {
			if (file.size > maxSize) {
				setError(`File "${file.name}" exceeds the 2GB size limit.`);
			} else {
				setSelectedFile(file);
				const fileURL = URL.createObjectURL(file);
				setPreview(fileURL);
				setError(''); // Clear any previous errors
			}
		}
	};
	// // for multiple file system
	// const [selectedFiles, setSelectedFiles] = useState(null);
	// const [previews, setPreviews] = useState([]);
	// const [error, setError] = useState('');
	// useEffect(() => {
	// 	// Cleanup the object URLs when component unmounts or previews change
	// 	return () => {
	// 		previews.forEach(file => URL.revokeObjectURL(file.url));
	// 	};
	// }, [previews]);
	// const handleFileChange = (event) => {
	// 	const files = Array.from(event.target.files);

	// 	const maxSize = 2 * 1024 * 1024 * 1024; // 2GB in bytes
	// 	// const maxSize = 1 * 1024 * 1024 * 1024; // 1GB in bytes

	// 	const validFiles = [];
	// 	const filePreviews = [];
	// 	files.forEach(file => {
	// 		if (file.size > maxSize) {
	// 			setError(`File "${file.name}" exceeds the 2GB size limit.`);
	// 		} else {
	// 			validFiles.push(file);
	// 			const fileURL = URL.createObjectURL(file);
	// 			filePreviews.push({ name: file.name, type: file.type, url: fileURL });
	// 		}
	// 	});
	// 	if (validFiles.length > 0) {
	// 		setSelectedFiles(validFiles);
	// 		setPreviews(filePreviews);
	// 		setError(''); // Clear any previous errors
	// 	}
	// };

	const [name, setName] = useState('');
	const handleNameChange = (e) => setName(e.target.value);

	const [victim, setVictim] = useState('');
	const handleVictimChange = (e) => setVictim(e.target.value);

	const [address, setAddress] = useState('');
	const handleAddressChange = (e) => setAddress(e.target.value);

	const [occupation, setOccupation] = useState('');
	const handleOccupationChange = (e) => setOccupation(e.target.value);

	const [email, setEmail] = useState('');
	const handleEmailChange = (e) => setEmail(e.target.value);

	const [victimConditionOptions, setVictimConditionOptions] = useState([
		{ value: 'মৃত', label: 'মৃত' },
		{ value: 'আহত', label: 'আহত' },
		{ value: 'নিখোঁজ', label: 'নিখোঁজ' },
		{ value: 'প্রযোজ্য নয়', label: 'প্রযোজ্য নয়' },
	]);
	// const [victimConditionOptionValue, setVictimConditionOptionValue] = useState(radioOptions[0]?.value || '');
	const [victimConditionOptionValue, setVictimConditionOptionValue] = useState("");

	const [radioOptions2, setRadioOptions2] = useState([
		{ value: 'option1', label: 'Option 1' },
		{ value: 'option2', label: 'Option 2' },
		{ value: 'option3', label: 'Option 3' },
	]); // need to change the name
	// const [radioOptionValue2, setRadioOptionValue2] = useState(radioOptions2[0]?.value || '');
	const [radioOptionValue2, setRadioOptionValue2] = useState(''); // need to change the name

	// const [captchaValue, setCaptchaValue] = useState(null);
	// console.log(captchaValue);


	const handleSubmit = event => {
		event.preventDefault();

		if (selectedCategoriesOptions.length > 0) {
			if (selectedFile) {     // for single file system
				// if (selectedFiles) {     // for multiple file system

				// if (!captchaValue) {
				// 	alert('Please complete the CAPTCHA');
				// 	return;
				// }

				const formData = new FormData();

				formData.append('date', date);
				formData.append('attack_places', selectedAttackPlaceCategoriesOptions);
				formData.append('categories', selectedCategoriesOptions);
				formData.append('source_type', selectedSourceTypeOption);
				formData.append('source_url', url);
				formData.append('short_description', shortDescription);
				formData.append('description', description);
				formData.append('file', selectedFile);   // for single file system
				// // for multiple file system
				// selectedFiles.forEach((file) => {
				// 	formData.append('files[]', file); // Append each file with a 'files[]' key
				// });
				formData.append('contributor_name', name);
				formData.append('victim_name', victim);
				formData.append('victim_address', address);
				formData.append('victim_occupation', occupation);
				formData.append('email', email);
				formData.append('victim_condition', victimConditionOptionValue);
				formData.append('radioOptionValue2', radioOptionValue2); // need to change the name

				// formData.append('captchaValue', captchaValue); // for captcha

				dispatch(dataSubmit(formData, token));
				setUploadFlag(true);

				setDate('');
				// setPlace('');
				setSelectedAttackPlaceCategoriesOptions([]);
				setSelectedCategoriesOptions([]);
				setSelectedSourceTypeOption('');
				setURL('');
				setShortDescription('');
				setDescription('');
				setSelectedFile('');  // for single file system
				setPreview('');  // for single file system
				// setSelectedFiles(null);  // for multiple file system
				// setPreviews([]);  // for multiple file system
				setName('');
				setVictim('');
				setAddress('');
				setOccupation('');
				setEmail('');
				setVictimConditionOptionValue('');
				setRadioOptionValue2(''); // need to change the name

				// setCaptchaValue('');
			}
			else {
				toast.error('Please select of at least one file to send.');
			}
		}
		else {
			toast.error('Please select of at least one category.');
		}
	};

	useEffect(() => {
		if (data.success) {
			toast.success(data.success);
			setUploadFlag(false);
		}
		if (data.error.message) {
			toast.error(data.error.message);
			setUploadFlag(false);
		}
	}, [data.error.message, data.success, dispatch]);


	return (
		<>
			<Navbar />

			<Container
				pb={{ base: '2', md: '4', }}
				px={{ base: '3', md: '8', }}
				maxWidth={"1400px"}
				mx={'auto'}
			>
				<Box
					// rowSpan={3}
					// colSpan={[5, 5, 3, 3, 3]}
					boxShadow='dark-lg'
					p={[3, 6]}
					rounded='md'
					w={'100%'}
					mb={5}
				>
					<VStack spacing={[5, 5, 5, 6]} align={'left'}>
						{/* Description Cards */}
						<DescriptionCards />
					</VStack>
				</Box>

				<Box
					// rowSpan={3}
					// colSpan={[5, 5, 3, 3, 3]}
					boxShadow='dark-lg'
					p={[3, 6]}
					rounded='md'
					w={'100%'}
				>
					<Text
						fontSize={["lg", "lg", "2xl"]}
						fontWeight="bold"
						color='black'
						textAlign={'center'}
						mb={2}
					>
						{welcomeText}
					</Text>

					<Text
						fontSize={["lg", "lg", "2xl"]}
						fontWeight="bold"
						color='black'
						textAlign={'center'}
						mb={2}
					>
						{instructionText}
					</Text>

					<Box>
						{/* <Form onSubmit={handleSubmit}> */}
						<Form>
							{/* <Input */}
							{/* Date */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{dateInputHeader}
								</Text>

								<input
									id={dateInputId}
									name={dateInputId}
									placeholder={dateInputPlaceholder}
									size='md'
									// type='datetime-local'
									type='date'
									// variant='filled'
									// width='100%'
									//required
									minLength="4"
									maxLength="8"
									// size="10"
									value={date}
									onChange={handleDateChange}
								/>
							</Stack>

							{/* Attack Place Categories */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'column', 'row']}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{attackPlaceCategoriesInputHeader}
								</Text>

								<Box ml={5} w={['90%', '70%', '50%', '30%']}>
									{/* <Button onClick={toggleDropdown}>
										{selectedCategoriesOptions.length > 0
											? `Total Selected (${selectedCategoriesOptions.length})`
											: `${categoriesInputPlaceholder}`}
									</Button> */}

									{/* {isOpen && ( */}
									<Box border="1px solid" borderColor="gray.200" borderRadius="md" mt={2} p={2} bg="white">
										{attackPlaceCategoriesOptions.map((option) => (
											<Box key={option.value}>
												<Checkbox
													isChecked={selectedAttackPlaceCategoriesOptions.includes(option.value)}
													onChange={() => handleAttackPlaceCheckboxChange(option.value)}
												>
													{option.label}
												</Checkbox>
											</Box>
										))}
									</Box>
									{/* )} */}
								</Box>
							</Stack>

							{/* Categories */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'column', 'row']}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{categoriesInputHeader}
								</Text>

								<Box ml={5} w={['90%', '70%', '50%', '30%']}>
									{/* <Button onClick={toggleDropdown}>
										{selectedCategoriesOptions.length > 0
											? `Total Selected (${selectedCategoriesOptions.length})`
											: `${categoriesInputPlaceholder}`}
									</Button> */}

									{/* {isOpen && ( */}
									<Box border="1px solid" borderColor="gray.200" borderRadius="md" mt={2} p={2} bg="white">
										{categoriesOptions.map((option) => (
											<Box key={option.value}>
												<Checkbox
													isChecked={selectedCategoriesOptions.includes(option.value)}
													onChange={() => handleCheckboxChange(option.value)}
												>
													{option.label}
												</Checkbox>
											</Box>
										))}
									</Box>
									{/* )} */}
								</Box>
							</Stack>

							{/* Source Type */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{sourceTypeInputHeader}
								</Text>

								{/* <Box width="700px" margin="0 auto"> */}
								<Select
									placeholder={sourceTypeInputPlaceholder}
									size={["md", "md", "lg"]}
									variant="filled"
									focusBorderColor="blue.500"
									borderRadius="md"
									boxShadow="sm"
									required
									onChange={handleSourceTypeChange}
									value={selectedSourceTypeOption}
								>
									{options.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</Select>

								{/* </Box> */}
							</Stack>

							{/* URL */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{urlInputHeader}
								</Text>

								<Input
									name={urlInputId}
									id={urlInputId}
									variant='filled'
									size={["md", "md", "lg"]}
									width='100%'
									p={3}
									// my={3}
									placeholder={urlInputPlaceholder}
									onChange={handleURLChange}
									//required
									value={url}
								// h={'50px'}
								/>
							</Stack>

							{/* short Description */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{shortDescriptionInputHeader}
								</Text>

								<Textarea
									name={shortDescriptionInputId}
									id={shortDescriptionInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									// my={3}
									placeholder={shortDescriptionInputPlaceholder}
									onChange={handleShortDescriptionChange}
									required
									value={shortDescription}
								// h={[null, '150px', '150px', '100px']}
								/>
							</Stack>

							{/* Description */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{descriptionInputHeader}
								</Text>

								<Textarea
									name={descriptionInputId}
									id={descriptionInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									// my={3}
									placeholder={descriptionInputPlaceholder}
									onChange={handleDescriptionChange}
									required
									value={description}
								// h={[null, '150px', '150px', '100px']}
								/>
							</Stack>

							{/* for Upload  */}
							<Box
								p={[1, 2]}
								mb={6}
								boxShadow={'md'}
								// maxWidth={'500px'}
								mx={'auto'}
							>
								{/* <Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}> */}
								<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
									<Text
										// border='2px'
										// borderColor='gray'
										// borderRadius="md"
										fontSize={["md", "md", "lg"]}
										fontWeight="semibold"
										// bg={'gray'}
										color='black'
										textAlign={'left'}
									// my={2}
									// p={3}
									// h={[null, '150px', '150px', '100px']}
									>
										{fileInputHeader}
									</Text>

									<Input
										type="file"
										onChange={handleFileChange}
										// multiple   // for multiple file system
										accept=".pdf,video/*, image/*"
										// accept=".pdf,.mp4,image/*"
										capture
										variant='filled'
									/>
								</Stack>

								{error && <p style={{ color: 'red' }}>{error}</p>}

								{/* // for single file system */}
								{/* <Stack gap={[2, 5]} direction={['column', 'column', 'row']}> */}
								{preview &&
									<Box p='2'>
										{selectedFile.type.includes('pdf') ? (
											<Box h={['200px', "300px", "400px", '500px']}>
												<iframe
													src={preview}
													title={selectedFile.name}
													width="100%"
													height="100%"
												></iframe>
											</Box>
										) : selectedFile.type.includes('image') ? (
											<Box w={['50%', '45%', '40%', '30%']} mx={'auto'}>
												<img
													src={preview}
													alt={selectedFile.name}
													style={{ maxWidth: '100%', height: 'auto' }}
												/>
											</Box>
										) :
											<Center>
												<video
													controls
													width="500"
													src={preview}
												>
													Your browser does not support the video tag.
												</video>
											</Center>
										}
									</Box>}
								{/* </Stack> */}

								{/* // for multiple file system */}
								{/* <Stack gap={[2, 5]} direction={['column', 'column', 'row']}> */}
								{/* <Grid
									templateRows='repeat(1, 1fr)'
									templateColumns='repeat(4, 1fr)'
								>
									{previews.map((file, index) => (
										<GridItem
											// rowSpan={3}
											colSpan={[4, 2, 1]}
											// boxShadow='dark-lg'
											p='2'
											// rounded='md'
											key={index}
										>
											{
												file.type.includes('pdf') ? (
													<Box h={['200px', "300px", "400px", '500px']}>
														<iframe
															src={file.url}
															title={file.name}
															width="100%"
															height="100%"
														></iframe>
													</Box>
												) : file.type.includes('image') ? (
													<Box w={'100%'} mx={'auto'}>
														<img
															src={file.url}
															alt={file.name}
															style={{ maxWidth: '100%', height: 'auto' }}
														/>
													</Box>
												) : (
													<video
														controls
														width="500"
														src={file.url}
													>
														Your browser does not support the video tag.
													</video>
												)
											}
										</GridItem>
									))}
								</Grid> */}
								{/* </Stack> */}

								{/* // upload before submit  */}
								{/* <Button
									bgColor={selectedFiles ? 'blue' : 'gray'}
									p={1} mt={2}
									borderRadius={'lg'}
									color={'white'}
									isDisabled={selectedFiles ? false : true}
									onClick={handleUpload}
								>
									Upload
								</Button> */}
							</Box>

							{/* Name */}
							<Stack gap={[2, 5]} marginYZ={3} direction={['column',]}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{nameInputHeader}
								</Text>

								<Input
									name={nameInputId}
									id={nameInputId}
									variant='filled'
									size={["md", "md", "lg"]}
									width='100%'
									p={3}
									// my={3}
									placeholder={nameInputPlaceholder}
									onChange={handleNameChange}
									// required
									value={name}
									h={'50px'}
								/>
							</Stack>

							{/* victim  */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{victimInputHeader}
								</Text>

								<Input
									name={victimInputId}
									id={victimInputId}
									variant='filled'
									size={["md", "md", "lg"]}
									width='100%'
									p={3}
									// my={3}
									placeholder={victimInputPlaceholder}
									onChange={handleVictimChange}
									// required
									value={victim}
									h={'50px'}
								/>
							</Stack>

							{/* victim address  */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{addressInputHeader}
								</Text>

								<Textarea
									name={addressInputId}
									id={addressInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									// my={3}
									placeholder={addressInputPlaceholder}
									onChange={handleAddressChange}
									// required
									value={address}
									h={'50px'}
								/>
							</Stack>

							{/* victim occupation  */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{occupationInputHeader}
								</Text>

								<Textarea
									name={occupationInputId}
									id={occupationInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									// my={3}
									placeholder={occupationInputPlaceholder}
									onChange={handleOccupationChange}
									// required
									value={occupation}
									h={'50px'}
								/>
							</Stack>

							{/* Email */}
							<Stack gap={[2, 5]} marginY={3} direction={['column',]}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{emailInputHeader}
								</Text>

								<Textarea
									name={emailInputId}
									id={emailInputId}
									variant='filled'
									resize="vertical"  // "horizontal", "both", or "none"
									width='100%'
									p={3}
									// my={3}
									placeholder={emailInputPlaceholder}
									onChange={handleEmailChange}
									// required
									value={email}
									h={'50px'}
								/>
							</Stack>

							{/* Victim Condition */}
							<Stack gap={[2, 5]} marginY={3} direction={['column', 'row']}>
								<Text
									// border='2px'
									// borderColor='gray'
									// borderRadius="md"
									fontSize={["md", "md", "lg"]}
									fontWeight="semibold"
									// bg={'gray'}
									color='black'
									textAlign={'left'}
								// my={2}
								// p={3}
								// h={[null, '150px', '150px', '100px']}
								>
									{victimConditionInputHeader}
								</Text>

								<RadioGroup onChange={setVictimConditionOptionValue} value={victimConditionOptionValue}>
									<Stack direction={['column', "column", 'row']}>
										{victimConditionOptions.map((radioOption) => (
											<Radio key={radioOption.value} value={radioOption.value}>
												{radioOption.label}
											</Radio>
										))}
									</Stack>
								</RadioGroup>
							</Stack>

							{/* radio button2 */}
							{/* // captcha  */}
							{/* <Center >
								<Captcha onChange={setCaptchaValue} />
							</Center> */}

							<Text textAlign={'center'} my={2}>
								<Button
									size="lg"
									bg={"blue"}
									color={"white"}
									_hover={{ bg: "blue.500" }}
									px={4}
									py={1}
									borderRadius={'lg'}
									isDisabled={uploadFlag}
									type='submit'
									onClick={(event) => {
										if (selectedCategoriesOptions && selectedSourceTypeOption && description) {
											handleSubmit(event);
										}
									}}
								>
									{uploadFlag ? `${submitWaitingText}` : `${submitButtonText}`}
								</Button>
							</Text>
						</Form>

					</Box>
				</Box >

			</Container >

			<Footer />
		</>
	);
};

export default Home;
