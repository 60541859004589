import React, { useEffect } from 'react';
import { useState } from "react";
import { Box, FormControl, FormLabel, Input, InputGroup, HStack, InputRightElement, Stack, Button, Heading, Text, Link, Container, Image } from "@chakra-ui/react";
import { Form, useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import toast from 'react-hot-toast';
import Captcha from '../Components/Captcha';

import { useDispatch, useSelector } from 'react-redux';
import { signup } from '../Redux/Thunk/Login';
import { selectIsLoggedIn, selectUserLoginErrorMessage } from '../Redux/Reducer';


const Register = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// //  after sign up redirect to login page 
	// const [registerFlag, setRegisterFlag] = useState(false);
	// useEffect(() => {
	// 	if (registerFlag) {
	// 		// navigate("/login");
	// 	}
	// }, [navigate, registerFlag]);

	// after sign in direct to home page 
	const isLoggedIn = useSelector(state => selectIsLoggedIn(state));
	useEffect(() => {
		if (isLoggedIn) {
			// window.location.href = '/';
			navigate("/home");
		};
	}, [isLoggedIn, navigate]);

	// error message show
	const error = useSelector(state => selectUserLoginErrorMessage(state));
	const [count, setCount] = useState(0);
	useEffect(() => {
		if (error.message && count) {
			toast.error(error.message);
		}
	}, [count, error.message, navigate]);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);

	const [captchaValue, setCaptchaValue] = useState(null);
	// console.log(captchaValue);

	// const name = firstName + ' ' + lastName;

	const handleSubmit = (event) => {
		event.preventDefault();

		if (!captchaValue) {
			alert('Please complete the CAPTCHA');
			return;
		}

		// dispatch(signup(firstName, lastName, email, password));
		dispatch(signup(firstName, lastName, email, password, captchaValue));
		setFirstName('');
		setLastName('');
		setEmail('');
		setPassword('');

		setCount(1);
		// setRegisterFlag(true);
		// console.log(firstName);
	};


	return (
		<Container
			minH={"100vh"}
			minW={'100vw'}
			align={"center"}
			justify={"center"}
			// bg={useColorModeValue("gray", "gray.800")}
			bg={"gray"}
		>
			<Stack
				// spacing={8}
				mx={"auto"}
				maxW={"lg"}
				py={{ base: '12', md: '12', }
				}
				px={{ base: '0', sm: '8', }}
			// bg={'teal'}
			// borderRadius={15}
			>
				<Box
					rounded={"lg"}
					// bg={useColorModeValue("teal", "gray.700")}
					bg={'white'}
					boxShadow={"lg"}
					px={[2, 4, 6, 8]}
					py={[4, 4, 6, 8]}
				>
					<Stack
						align={"center"}
						// bg={'teal'}
						// rounded={"lg"}
						// bg={useColorModeValue("teal", "gray.700")}
						// boxShadow={"lg"}
						p={8}
					>
						<Button
							onClick={() => navigate("/")}
							// bg={'transparent'}
							bgColor={'transparent'}
							color='white'
							_hover={{ bg: "transparent", color: "white" }}
						>
							{/* <Logo /> */}
							<Image
								src="/assets/BangladeshConflctWatch.png"
								alt="Logo"
								m={{ base: '2', md: '2' }}
								w={'60%'}
							/>
						</Button>

						<Text color="fg.muted">
							Have an account?
							<Button
								bgColor={'black'}
								color='white'
								_hover={{ bg: "gray", color: "white" }}
								p={1}
								borderRadius={'lg'}
								ml={3}
							>
								<Link href="/login" color={'white'}> Log in</Link>
							</Button>
						</Text>

						<Heading
							fontSize={"2xl"}
							fontWeight={'bold'}
							textAlign={"center"}
						>
							Sign Up
						</Heading>
					</Stack>

					<Form onSubmit={handleSubmit}>
						<Stack spacing={4}>
							<HStack >
								<Box w={'50%'}>
									<FormControl id="firstName" isRequired >
										<FormLabel fontWeight={'bold'}>First Name</FormLabel>
										<Input
											type="text"
											placeholder='Your First Name'
											px={2}
											w={'100%'}
											onChange={(e) => setFirstName(e.target.value)}
											value={firstName}
											isRequired
										/>
									</FormControl>
								</Box>

								<Box w={'50%'}>
									<FormControl id="lastName">
										<FormLabel fontWeight={'bold'}>Last Name</FormLabel>
										<Input
											type="text"
											placeholder='Your Last Name'
											px={2}
											w={'100%'}
											onChange={(e) => setLastName(e.target.value)}
											value={lastName}
										/>
									</FormControl>
								</Box>
							</HStack>

							<FormControl id="email" isRequired>
								<FormLabel fontWeight={'bold'}>Email address</FormLabel>
								<Input
									type="email"
									placeholder='Your Email Address'
									px={2}
									w={'100%'}
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									isRequired
								/>
							</FormControl>

							<FormControl id="password" isRequired>
								<FormLabel fontWeight={'bold'}>Password</FormLabel>
								<InputGroup>
									<Input
										type={showPassword ? "text" : "password"}
										placeholder='Type your Password here'
										px={2}
										w={'90%'}
										value={password}
										isRequired
										onChange={(e) => setPassword(e.target.value)}
									/>

									<InputRightElement
										h={"full"}
										color={'black'}
										px={[0, 1, 2]}
									>
										<Button
											variant={"ghost"}
											onClick={() => setShowPassword((showPassword) => !showPassword)}
										>
											{showPassword ? <ViewIcon /> : <ViewOffIcon />}
										</Button>
									</InputRightElement>
								</InputGroup>
							</FormControl>

							<Captcha onChange={setCaptchaValue} />

							<Stack spacing={10} pt={2}>
								<Button
									loadingText="Submitting"
									size="lg"
									bgColor={'blue'}
									color={'white'}
									_hover={{ bg: "blue.500", color: "white" }}
									p={1}
									borderRadius={'lg'}
									type='submit'
								>
									Sign up
								</Button>
							</Stack>
						</Stack>
					</Form>
				</Box>
			</Stack>
		</Container>
	);
};

export default Register;  