import { combineReducers } from "redux";
import {
    REQUEST_SUBMIT_DATA,
    SUCCESS_SUBMIT_DATA,
    FAILURE_SUBMIT_DATA,
    // REQUEST_SUBMIT_FILE_UPLOAD,
    // SUCCESS_SUBMIT_FILE_UPLOAD,
    // FAILURE_SUBMIT_FILE_UPLOAD,
} from "../Constant/home";

const initialState = {
    data: {
        // name: '',
        // dataset_id: 0,
        // datastream_id: 0,
        // line_number: 0,
        // source_language: '',
        // line: '',
        success: '',
        error: "",
        // uploadMessage: "",
    },
    isFetching: false,
}

const data = (state = initialState.data, action) => {
    switch (action.type) {

        case REQUEST_SUBMIT_DATA:
            return {
                ...state,
                success: '',
                error: '',
            }
        case SUCCESS_SUBMIT_DATA:
            return {
                ...state,
                success: action.payload.message,
                error: '',
            }
        case FAILURE_SUBMIT_DATA:
            return {
                ...state,
                success: '',
                error: action.payload,
            }

        // case REQUEST_SUBMIT_FILE_UPLOAD:
        //     return {
        //         ...state,
        //         // success: '',
        //         // error: '',
        //         uploadMessage: '',
        //     }
        // case SUCCESS_SUBMIT_FILE_UPLOAD:
        //     return {
        //         ...state,
        //         uploadMessage: action.payload.message,
        //         // success: action.payload.message,
        //         // error: '',
        //     }
        // case FAILURE_SUBMIT_FILE_UPLOAD:
        //     return {
        //         ...state,
        //         uploadMessage: action.payload.message,
        //         // success: '',
        //         // error: action.payload,
        //     }

        default:
            return state;
    }
};

const isFetching = (state = initialState.isFetching, action) => {
    switch (action.type) {
        case REQUEST_SUBMIT_DATA:
            // case REQUEST_SUBMIT_FILE_UPLOAD:
            return true;

        // case SUCCESS_SUBMIT_FILE_UPLOAD:
        // case FAILURE_SUBMIT_FILE_UPLOAD:
        case SUCCESS_SUBMIT_DATA:
        case FAILURE_SUBMIT_DATA:
            return false;

        default:
            return state;
    }
};

export default combineReducers({
    data,
    isFetching,
});

export const selectData = state => state.data;



// export const selectLine = state => state.lines;
// export const selectNewLine = state => state.lines;
// export const selectTranslatedLine = state => state.lines;
