//const BASE_URL = "http://localhost:9090";
const BASE_URL = "https://bdconflict.watch";


// login
export const signup_url = `${BASE_URL}/api/v1/users/registration`;                    // ok
export const login_url = `${BASE_URL}/api/v1/users/login`;                            // ok
export const logout_url = `${BASE_URL}/api/v1/users/logout`;                          // ok
export const profileUpdate_url = `${BASE_URL}/api/v1/profile_edit`;


// home
export const data_url = `${BASE_URL}/api/v1/evidence/create`;                          // ok

