import {
    REQUEST_SUBMIT_DATA,
    SUCCESS_SUBMIT_DATA,
    FAILURE_SUBMIT_DATA,
    // REQUEST_SUBMIT_FILE_UPLOAD,
    // SUCCESS_SUBMIT_FILE_UPLOAD,
    // FAILURE_SUBMIT_FILE_UPLOAD,

    // UPDATE_SESSION_EXPIRY,
} from "../Constant/home";



export const requestSubmitData = () => ({
    type: REQUEST_SUBMIT_DATA,
})
export const submitDataSuccess = (data) => ({
    type: SUCCESS_SUBMIT_DATA,
    payload: data,
})
export const submitDataFailure = (error) => ({
    type: FAILURE_SUBMIT_DATA,
    payload: error,
})

// export const requestSubmitFileUpload = () => ({
//     type: REQUEST_SUBMIT_FILE_UPLOAD,
// })
// export const submitFileUploadSuccess = (data) => ({
//     type: SUCCESS_SUBMIT_FILE_UPLOAD,
//     payload: data,
// })
// export const submitFileUploadFailure = (error) => ({
//     type: FAILURE_SUBMIT_FILE_UPLOAD,
//     payload: error,
// })

// export const updateSessionExpiry = (nextDay) => ({
//     type: UPDATE_SESSION_EXPIRY,
//     payload: nextDay,
// })
