import React, { useEffect, useState } from 'react';
import { forwardRef, useRef } from 'react';
import { IconButton, InputGroup, InputRightElement, useDisclosure, useMergeRefs, Button, Box, Checkbox, Container, FormControl, FormLabel, Heading, HStack, Input, Link, Stack, Text, Image, VStack, } from '@chakra-ui/react';
import { Form, useNavigate } from 'react-router-dom';
import { HiEye, HiEyeOff } from 'react-icons/hi';

import toast from 'react-hot-toast';
import Captcha from '../Components/Captcha';
import FBLogin from '../Components/FBLogin';
import GoogleLoginButton from '../Components/GoogleLoginButton';

import { useDispatch, useSelector } from 'react-redux';
import { login } from '../Redux/Thunk/Login';
import { selectIsLoggedIn, selectUserLoginErrorMessage } from '../Redux/Reducer';


const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// after sign in direct to home page 
	const isLoggedIn = useSelector(state => selectIsLoggedIn(state));
	// navigate("/home");
	useEffect(() => {
		if (isLoggedIn) {
			// window.location.href = '/';
			navigate("/home");
		};
	}, [isLoggedIn, navigate]);

	// error message show
	const error = useSelector(state => selectUserLoginErrorMessage(state));
	const [count, setCount] = useState(0);
	useEffect(() => {
		if (error.message && count) {
			toast.error(error.message);
		}
	}, [count, error.message, navigate]);


	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	// for password field
	const { isOpen, onToggle } = useDisclosure()
	const inputRef = useRef(null)
	const mergeRef = useMergeRefs(inputRef, forwardRef.ref)
	const onClickReveal = () => {
		onToggle()
		if (inputRef.current) {
			inputRef.current.focus({
				preventScroll: true,
			})
		}
	}

	const [captchaValue, setCaptchaValue] = useState(null);
	// console.log(captchaValue);


	const handleSubmit = (event) => {
		event.preventDefault();

		if (!captchaValue) {
			alert('Please complete the CAPTCHA');
			return;
		}

		// dispatch(login(email, password, 'admin'));
		dispatch(login(email, password, 'admin', captchaValue));
		setEmail('');
		setPassword('');

		setCount(1);
		// console.log(email);
		// console.log(password);
	};

	return (
		<Box
			bg={'gray'}
			minH={"100vh"}
			// w={'100%'}
			align={"center"}
			justify={"center"}
		>
			< Container
				maxW="lg"
				mx={'auto'}
				py={{ base: '12', md: '12', }
				}
				px={{ base: '0', sm: '8', }}
			>
				<Stack
					spacing="4"
					border='2px'
					borderColor='green'
					py={2}
					bg={'white'}
					borderRadius={15}
				>
					<Stack spacing="6">
						<Button
							onClick={() => navigate("/")}
							// bg={'transparent'}
							bgColor={'transparent'}
							color='white'
							_hover={{ bg: "transparent", color: "white" }}
							mt={2}
						>
							{/* <Logo /> */}
							<Image
								src="/assets/BangladeshConflctWatch.png"
								alt="Logo"
								m={{ base: '2', md: '2' }}
								w={'40%'}
							/>
						</Button>

						<Stack spacing={{ base: '2', md: '3', }} textAlign="center">
							<Heading fontSize={{ base: 'lg', md: 'xl', }} fontWeight={'bold'}>
								Log in to your account
							</Heading>

							<Text color="fg.muted">
								Don't have an account?
								<Button
									bgColor={'black'}
									color='white'
									_hover={{ bg: "gray", color: "white" }}
									p={1}
									borderRadius={'lg'}
									ml={3}
								>
									<Link href="/register" > Sign up</Link>
								</Button>
							</Text>
						</Stack>
					</Stack>

					<Box
						pt={{ base: '0', sm: '4', }}
						pb={{ base: '0', sm: '0', }}
						px={{ base: '4', sm: '10', }}
					// bg={{ base: 'transparent', sm: 'bg.surface', }}
					// boxShadow={{ base: 'none', sm: 'md', }}
					// borderRadius={{ base: 'none', sm: 'xl', }}
					>
						<Form onSubmit={handleSubmit}>
							<Stack spacing="4">
								<Stack spacing="2" >
									<FormControl isRequired>
										<FormLabel htmlFor="email" fontWeight={'bold'} >Email</FormLabel>
										<Input
											id="email"
											type="email"
											placeholder='Your Email Address'
											px={2}
											w={'100%'}
											onChange={(e) => setEmail(e.target.value)}
											value={email}
											isRequired
										/>
									</FormControl>

									<FormControl isRequired>
										<FormLabel htmlFor="password" fontWeight={'bold'}>Password</FormLabel>

										<InputGroup>
											<InputRightElement color={'black'} p={1} >
												<IconButton
													variant="text"
													aria-label={isOpen ? 'Mask password' : 'Reveal password'}
													icon={isOpen ? <HiEyeOff /> : <HiEye />}
													onClick={onClickReveal}
												/>
											</InputRightElement>

											<Input
												id="password"
												ref={mergeRef}
												name="password"
												type={isOpen ? 'text' : 'password'}
												autoComplete="current-password"
												placeholder='Type your Password here'
												px={2}
												w={'90%'}
												onChange={(e) => setPassword(e.target.value)}
												value={password}
												isRequired
											/>
										</InputGroup>
									</FormControl>
								</Stack>

								<HStack justify="space-between">
									<Checkbox defaultChecked color={'black'} >Remember me</Checkbox>

									<Button variant="text" size="sm" color={'red'}>
										Forgot password ?
									</Button>
								</HStack>

								<Captcha onChange={setCaptchaValue} />

								{/* <Stack spacing="4"> */}
								<Button
									bgColor={'blue'}
									color={'white'}
									_hover={{ bg: "blue.500", color: "white" }}
									p={1}
									borderRadius={'lg'}
									type='submit'
								>
									Sign in</Button>
								{/* </Stack> */}
							</Stack>
						</Form>

					</Box>

					<VStack my={1}>
						<Text textAlign={'center'} fontWeight={'bold'}>Login With </Text>

						<HStack spacing={3} >
							<FBLogin />
							<GoogleLoginButton />
						</HStack>
					</VStack>
				</Stack >
			</Container >
		</Box >
	);
};

export default Login;