import { combineReducers } from "redux";
import data, * as fromData from "./data";
import user, * as fromUser from "./login";


export const rootReducer = combineReducers({
    "data": data,
    "user": user,
});

const reducerFunction = (state, action) => rootReducer(state, action);
export default reducerFunction;


export const selectData = state => fromData.selectData(state.data);

export const selectID = state => fromUser.selectUserID(state.user);
export const selectToken = state => fromUser.selectToken(state.user);
export const selectIsLoggedIn = state => fromUser.selectIsLoggedIn(state.user);
export const selectIsAdmin = state => fromUser.selectIsAdmin(state.user);
export const selectProfile = state => fromUser.selectProfile(state.user);
export const selectUserLoginErrorMessage = state => fromUser.selectUserLoginErrorMessage(state.user);


// admin
// export const selectAllUserData = state => fromUserManagement.selectAllUserData(state.userManagement.usersData);
