import React, { useEffect, useState } from 'react';
// import { GoogleLogin } from '@react-oauth/google';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { FaGoogle } from 'react-icons/fa';
import axios from 'axios';
import { Button } from '@chakra-ui/react';


const GoogleLoginButton = (props) => {

	const [user, setUser] = useState([]);
	const [profile, setProfile] = useState([]);

	const login = useGoogleLogin({
		onSuccess: (codeResponse) => setUser(codeResponse),
		onError: (error) => alert('Login Failed:', error)
	});

	// fetch user data after login and set in profile
	useEffect(
		() => {
			if (user) {
				axios
					.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
						headers: {
							Authorization: `Bearer ${user.access_token}`,
							Accept: 'application/json'
						}
					})
					.then((res) => {
						setProfile(res.data);
					})
					.catch((err) => console.log(err));
			}
		},
		[user]
	);

	// log out function to log the user out of google and set the profile array to null
	const logOut = () => {
		googleLogout();
		setProfile(null);
	};

	// const responseMessage = (response) => {
	// 	console.log(response);
	// };
	// const errorMessage = (error) => {
	// 	console.log(error);
	// };

	return (
		// <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
		<Button onClick={login} padding={0} bg={'transparent'} marginBottom={1}>
			{/* Sign in with Google 🚀  */}
			<FaGoogle size={27} />
		</Button>
	);
};

export default GoogleLoginButton;
